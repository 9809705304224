import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Error = ({ stroke, fill, ...props }: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="19" r="1.25" strokeWidth="0.5" />
            <path d="M12 14L12 5" strokeWidth="2" strokeLinecap="round" />
        </SvgIcon>
    );
};
