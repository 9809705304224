import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Calendar = (props: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M17.4444 5.5L6.55556 5.5C5.69645 5.5 5 6.17157 5 7L5 17.5C5 18.3284 5.69645 19 6.55556 19H17.4444C18.3036 19 19 18.3284 19 17.5V7C19 6.17157 18.3036 5.5 17.4444 5.5Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M15 4V6.8125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 4V6.8125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 9.625L19 9.625" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
};
