import React from "react";

import { SvgIcon, SvgIconProps } from "@sol/uikit/core/icons/SvgIcon";

type Props = { className?: string };

export const Plus = ({ className, ...props }: SvgIconProps & Props) => {
    return (
        <SvgIcon {...props} className={className}>
            <line x1="12" y1="7" x2="12" y2="17" strokeWidth="2" strokeLinecap="round" />
            <line x1="7" y1="12" x2="17" y2="12" strokeWidth="2" strokeLinecap="round" />
        </SvgIcon>
    );
};
