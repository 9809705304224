import { cva, VariantProps } from "class-variance-authority";
import { FC, HTMLAttributes } from "react";

type SvgIconVariantProps = VariantProps<typeof svgIconVariants>;

const svgIconVariants = cva(
    [
        "shrink-0",
        "border-solid",
        "rounded-[50%]",
        "box-content",
        "shape-geometric-precision",
        "transition-all",
        "ease-in-out",
        "duration-200",
    ],
    {
        variants: {
            size: {
                12: ["w-3", "h-3"],
                16: ["w-3", "h-3"],
                24: ["w-6", "h-6"],
                32: ["w-6", "h-6"],
                48: ["w-6", "h-6"],
            },
        },
    },
);

export interface SvgIconProps extends HTMLAttributes<HTMLOrSVGElement>, SvgIconVariantProps {
    size?: 12 | 16 | 24 | 32 | 48;
    fill?: string;
    stroke?: string;
    bgColor?: string;
    animation?: string;
    className?: string;
    showFocusableProps?: boolean;
}

export const SvgIcon: FC<SvgIconProps> = ({
    size = 24,
    fill = "current",
    stroke = "current",
    bgColor = "transparent-base",
    animation,
    children,
    className,
    showFocusableProps = true,
    ...props
}) => {
    const iconBorder = `border-icon-${size}`;
    const fillColor = `fill-${fill}`;
    const strokeColor = `stroke-${stroke}`;
    const backgroundColor = `bg-${bgColor}`;
    const borderColor = `border-${bgColor}`;

    const focusableProps = showFocusableProps
        ? {
              focusable: false,
              "aria-hidden": true,
          }
        : {};

    const combinedClassName = `${iconBorder} ${fillColor} ${strokeColor} ${backgroundColor} ${borderColor}`;

    return (
        <svg
            className={svgIconVariants({
                size,
                className: `${combinedClassName} ${animation ?? ""} ${className ?? ""}`,
            })}
            height={24}
            width={24}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            {...focusableProps}
            {...props}
        >
            {children}
        </svg>
    );
};
